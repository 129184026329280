import Navbar from "./Navbar";

function App() {

  return (
    <div id="app" className="container-fluid d-flex flex-column h-100 justify-content-between" >

      <div className="row">
      <Navbar />
      </div>

      <div className="row justify-content-center align-items-center overflow-auto bg-info h-100">
        Body      
      </div>
      

      <div className="row justify-content-center bg-secondary bg-gradient" target="_blank">
        footer
      </div>

    </div>
  );
}

export default App;
