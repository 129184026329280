function Navbar() {
      
    return (
<nav className="navbar navbar-expand-md bg-body-tertiary">
  <div className="container-fluid">
    <a className="navbar-brand" href="#">KanariaPatterns</a>

    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>

    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div className="navbar-nav">
        <a className="nav-link" aria-current="page" href="#">Inicio</a>
        <a className="nav-link" href="#">Drag</a>
        <a className="nav-link" href="#">Mujer</a>
        <a className="nav-link" href="#">Hombre</a>
        <a className="nav-link" href="#">Niños</a>
        <a className="nav-link" href="#">Accesorios</a>
      </div>
    </div>
  </div>
</nav>
    );
  }
  
  export default Navbar;